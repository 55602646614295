import CryptoJS from 'crypto-js'; // npm install @types/crypto-js

import { auth } from "../../firebase-config"
import { confirmPasswordReset, sendPasswordResetEmail, verifyPasswordResetCode } from 'firebase/auth';
import { navigate } from 'gatsby';
import { ENABLE_ERROR_LOGGING } from '../settings/project-settings';


export const firebaseErrorCodes = {
    usernameTaken : 'auth/email-already-in-use',
    userNotFound : 'auth/user-not-found',
    invalidEmailReset : 'auth/invalid-email',
    invalidResetCode : 'auth/invalid-action-code',
    wrongPassword : 'auth/wrong-password'
}

export const encryptWithAES = ( password : string, text : string ) => {
    return CryptoJS.AES.encrypt(text, password).toString();
};
  
export const decryptWithAES = (password : string, ciphertext : string ) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, password);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
};

export const setTempSavePW = ( key : string, pw : string ) => {
    if (typeof window !== 'undefined') {
        const cypher : string = encryptWithAES(key, pw)
        localStorage.setItem('tempSavePW', cypher);
    }
}

export const deleteTempSavePW = () => {
    if (typeof window !== 'undefined') {
        localStorage.setItem('tempSavePW', "");      
    }
}
export const getOnceTempSavePW = ( key : string ) => {
    if (typeof window !== 'undefined') {
        const item : string | null = localStorage.getItem('tempSavePW')
        const cypher : string =  item ? item as string: ""
        const plaintext = decryptWithAES(key, cypher)
        deleteTempSavePW()
        return plaintext as any as string          
    }
}

export const getTempSavePW = ( key : string ) => {
    if (typeof window !== 'undefined') {
        const item : string | null = localStorage.getItem('tempSavePW')
        const cypher : string =  item ? item as string: ""
        const plaintext = decryptWithAES(key, cypher)
        return plaintext as any as string          
    }
}


export const setCustomRedirectOnSuccessfulRegisration = ( uid : string, url : string ) => {
    if (typeof window !== 'undefined') {
        if( ENABLE_ERROR_LOGGING ) console.log("attempting to set redirect to: " + url )
        const storageId : string = `customRedirectOnSuccessfulRegisration-${uid}`
        localStorage.setItem(storageId, url );
    }
}

export const useCustomRedirectOnSuccessfulRegisrationIfExists = ( uid : string ) => {
    if (typeof window !== 'undefined') {
        const storageId : string = `customRedirectOnSuccessfulRegisration-${uid}`
        const url : string = localStorage.getItem(storageId) ? localStorage.getItem(storageId) as string: ""
        localStorage.setItem(storageId, "");
        if( ENABLE_ERROR_LOGGING ) console.log("attempting to redirect to: " + url )
        if( url !== "" ){
            navigate(url)
            return true
        }
        else navigate("/dashboard")
    } 
    return false
}

export const sendAuthEmailResetPassword = ( username : string, action ?: {
    onInvalidEmail ?: () => void,
    onValidEmail ?: () => void,
}) => {
    sendPasswordResetEmail( auth, username).then(()=>{
        if( action && action.onValidEmail ) action.onValidEmail()

    }).catch(( error )=>{
        if( ENABLE_ERROR_LOGGING ) console.log(error.code);
        //if( error.code === firebaseErrorCodes.invalidEmailReset && action && action.onInvalidEmail ) 
            if( action && action.onInvalidEmail ) action.onInvalidEmail()
    })
}


export const sendAuthEmailChangePassword = ( code : string, newPassword : string, action ?: {
    onSuccessfulChange ?: () => void,
    onUnsuccessfulChange ?: () => void,
}) => {

    confirmPasswordReset( auth, code, newPassword ).then(()=>{
        if(action && action.onSuccessfulChange) action.onSuccessfulChange()

    }).catch(( error )=>{
        alert("sendAuthEmailChangePassword error: " + error.code)
        if(action && action.onUnsuccessfulChange) action.onUnsuccessfulChange()

    })
}

export const isValidOOBCode = ( code : any, action ?: {
    // only verififies of an oob-code hasn't been used or
    // replaced. It does not check, if an oob-code is being
    // applied directly. For example if you run an oob-code
    // from a registration email it will return true, but this
    // does not mean it can be applied to a password reset,
    // for example. 
    
    onValidCode ?: ( code : any ) => void
    onInvalidCode ?: ( code : any ) => void
}) => {

    verifyPasswordResetCode(auth, code).then(()=>{
        if( action && action.onValidCode ) action.onValidCode( code )
    }).catch(( error )=>{
        
        if( error.code === firebaseErrorCodes.invalidResetCode && action && action.onInvalidCode) 
            action.onInvalidCode( error.code )

    })
}


export const changePassword = async ( code : string, newPassword : string, action ?: {
    onSuccessfulChange ?: () => void,
    onUnsuccessfulChange ?: ( errorCode : any ) => void,
}) => {

    await confirmPasswordReset( auth, code, newPassword ).then(( response : any )=>{
        if( ENABLE_ERROR_LOGGING ) console.log(response)
        if(action && action.onSuccessfulChange) action.onSuccessfulChange()

    }).catch(( error )=>{
        alert("changePassword error: " + error.code)
        if(action && action.onUnsuccessfulChange) action.onUnsuccessfulChange( error.code )

    })

}