import { signInWithEmailAndPassword } from 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useState } from 'react'
import { auth } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { Signin } from '../../locust/components/Signin';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import X from '../../locust/composables/X';
import { Button, CircularProgress, TextField } from '@mui/material';
import { FACEBOOK_BLACK } from '../../assets/colors/Colors';
import SpacerV from '../../locust/sections/SpacerV';
import { AuthLogo, AuthLogoLgCentered, FineprintRsp, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from '../../locust/components/Auth';
import { getUIDMain, getUserEmail, getUserToken } from '../../service/user-service';
import { changePassword, isValidOOBCode, sendAuthEmailResetPassword } from '../../service/auth-service';
import { ToastContainer, toast } from 'react-toastify';
import { ENABLE_ERROR_LOGGING, ENDPOINT_128 } from '../../settings/project-settings';
import { quickCall } from '../../service/endpoints-service';


enum PageState {
    INPUT_EMAIL,
    INPUT_NEW_PASSWORD,
    PROCESSING,
    RESET_EMAIL_SENT
}

enum Constants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
}

const ProgressValue = (props : {  on ?: boolean } ) => 
    props.on ? <CircularProgress style={{ height : 40, width : 40, color : FACEBOOK_BLACK}}/>
    : <></>
    
const Content = {
    signup : {
        title : `It's Who You Know`,
        subtitle : 'Password Reset',
        fineprint  : 'By using this website, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        hasAccount : `Don't have an Account?`,
        signUpBtn : 'Sign In'
    }
}




export default() => {

    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState( "")
    const [password, setPassword] = React.useState("")
    const [buttonValue, setButtonValue] = React.useState<any>(undefined)
    const [pageState, setPageState] = React.useState(PageState.INPUT_EMAIL)
    const [subtitle, setSubtitle] = React.useState<any>("Send password reset email")
    const [oobCode, setOobCode] = React.useState<string>("")
    const [fineprintColor, setFineprintColor ] = useState('silver')
    const [fineprintValue, setFineprintValue ] = useState(Content.signup.fineprint)
    
    React.useEffect(()=>{
        setUsername(getUserEmail())

        const params = new URLSearchParams(window.location.href);
        const oobCodeInput = params.get("oobCode")
        if( oobCodeInput !== null ){
            setOobCode(oobCodeInput)
            setPageState(PageState.PROCESSING)
            isValidOOBCode(oobCodeInput,{
                onValidCode : () => {
                    setSubtitle("Input your New Password")
                    setPageState(PageState.INPUT_NEW_PASSWORD)
                    //alert("valid reset code")  
                },
                onInvalidCode : () => {
                    alert("Invalid reset code")
                }
            })
        }
    },[])
    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader 
                    type={'store'}
                    shoppingCart={Object.keys(getShoppingCart()).length}/>
                
                <SpacerV h={Constants.GUTTER}/>
                <AuthLogoLgCentered/>
                <SpacerV h={Constants.GUTTER}/>
                
                <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
                <SubtitleRsp c={FACEBOOK_BLACK}>{Content.signup.subtitle}</SubtitleRsp>
                    
                <SpacerV h={Constants.GUTTER}/>

                {pageState === PageState.PROCESSING && 
                    <>
                        <X>
                            <ProgressValue on/>
                        </X>
                    </>
                }

                { pageState === PageState.RESET_EMAIL_SENT && <>
                
                    <SubtitleIIRsp c={FACEBOOK_BLACK} >{"Please Check your Email"}</SubtitleIIRsp>
                        

                    <SpacerV h={Constants.FINEPRINT_GUTTER}/>
                    <X style={{ height : 55}}>
                        <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                    </X>
            
                
                </>}
                
                {pageState === PageState.INPUT_EMAIL && 
                    <>
                        <SubtitleIIRsp c={FACEBOOK_BLACK} >{subtitle}</SubtitleIIRsp>
                        

                        <SpacerV h={Constants.FINEPRINT_GUTTER}/>
                        <X style={{ height : 55}}>
                            <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                        </X>


                        <X style={{ height : 55 }}>
                            <TextField value={ username } 
                                id="outlined-basic" 
                                label="Email" 
                                variant="outlined" 
                                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                                onChange={( event )=>{
                                    setUsername(event.target.value)
                                }}
                            />
                        </X>

        
                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <Button
                                style={{ 
                                    width : 350, 
                                    height : 55, 
                                    marginLeft : 10,
                                    marginRight : 10,
                                    color: 'white',
                                    backgroundColor: FACEBOOK_BLACK, 
                                    border : `2px solid ${FACEBOOK_BLACK}`

                                }}
                                type="submit"
                                
                                variant="contained"
                                onClick={()=>{
                                    setPageState( PageState.PROCESSING )
                                    sendAuthEmailResetPassword(username,{
                                        onValidEmail : () => {
                                            setPageState( PageState.RESET_EMAIL_SENT )
                                        },
                                        onInvalidEmail : () => {
                                            setTimeout(()=>{
                                                setSubtitle(<X><div style={{ height : 30 }}><ProgressValue on/></div></X>)
                                                setTimeout(()=>{
                                                    toast(username + " does not exist!");
                                                    setSubtitle("Send password reset email")
                                                    setPageState( PageState.INPUT_EMAIL )
                                                },1500)
                                            },500)
                                        },
                                    })
                                    
                                }}
                            >Send</Button>
                        </X>
                    </>
                }
                {
                    pageState === PageState.INPUT_NEW_PASSWORD && <>

                        <SubtitleIIRsp c={FACEBOOK_BLACK}>{subtitle}</SubtitleIIRsp>
                        <SpacerV h={Constants.GUTTER/2}/>

                        <SpacerV h={Constants.FINEPRINT_GUTTER}/>
                        <X style={{ height : 55}}>
                            <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                        </X>
        

                        <X style={{ height : 55 }}>
                            <TextField value={ password } 
                                type="password"
                                id="outlined-basic" 
                                label="Password"
                                variant="outlined" 
                                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                                onChange={( event )=>{
                                    setPassword(event.target.value)
                                }}
                            />
                        </X>

                        <SpacerV h={Constants.FIELD_GUTTER}/>
                        <X style={{ height : 55 }}>
                            <Button
                                style={{ 
                                    width : 350, 
                                    height : 55, 
                                    marginLeft : 10,
                                    marginRight : 10,
                                    color: 'white',
                                    backgroundColor: FACEBOOK_BLACK, 
                                    border : `2px solid ${FACEBOOK_BLACK}`

                                }}
                                type="submit"
                                
                                variant="contained"
                                onClick={()=>{
                                   

                                    changePassword(oobCode, password, {
                                        onSuccessfulChange : async () => {
                                            //const token : string = await getUserToken()
                                            if( ENABLE_ERROR_LOGGING ) console.log('changing password to: ' + password )
                                            toast("Password changed successfully ")
                                            //signInWithEmailAndPassword(auth,)
                                            

                                            setTimeout(()=>{
                                                navigate("/auth/sign-in")
                                            },1000)

                                        },
                                        onUnsuccessfulChange : ( errorCode ) => {
                                            
                                            toast("Password change failed: " + JSON.stringify( errorCode ));

                                        },
                                    })

                                    
                                }}
                            >Send</Button>
                        </X>
                    
                    
                    
                    
                    </>

                }
                



            </LayoutFS>
            
            
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}



export const resetPasswordStorage = async ( props : { token : string, pw : string, onSuccess ?: ( res : any ) => void  }) => {
    const quickCallParams : any = {
        token : props.token,
        endpoint : ENDPOINT_128,
        key: "reset-password",
        params : {
            pw : props.pw,  
        },
        onSuccess: async ( res : any ) => {
            if( props.onSuccess ) props.onSuccess( res )
        }
    }
    return await quickCall(quickCallParams)
}



